<template>
  <div class="popup">
    <!-- <div class="popupBox"> -->
      <el-dialog :visible.sync="showAd" class="DialogBox" width="40%">
        <el-carousel height="400px" max-height="500px" autoplay :interval="5000" indicator-position="outside">
          <el-carousel-item v-for="item in swiperList" :key="item.id">
            <img :src="item.filePath" alt="" @click="goRoute(item.routeContent)"/>
          </el-carousel-item>
        </el-carousel>
      </el-dialog>
    <!-- </div> -->
  </div>
</template>

<script>
import { getBanner } from "@/request/home/home";
// import Advertisement from "@/components/advertisement/advertisement";
// import Navigation from "@/components/Navigation/Navigation";
export default {
  name: "popupAd",
  components: {
    // Advertisement,
    // Navigation,
  },
  data() {
    return {
      swiperList: [],
      showAd: false,
    };
  },
  created() {
    this.getBBannerList();
  },
  methods: {
    // GoIsShow() {
    //   this.$refs.Navigation;
    // },
    goRoute(url) {
      if (url) {
        // window.open(url, "_blank");
        this.showAd = false
        if (url.indexOf('?') > 0) {
          const [trueUrl, queryStr] = url.split('?')
          const params = queryStr.split('&')
          let paramObj = {}
          params.forEach(param => {
            const [key, value] = param.split('=')
            paramObj[key] = decodeURIComponent(value)
          });
          this.$router.push({ path: trueUrl, query: paramObj });
        } else {
          this.$router.push({ path: url });
        }
      } else {
        return;
      }
    },
    getBBannerList() {
      getBanner({ advertiseType: 12 }).then((res) => {
        if (res.data && res.data.length > 0) {
          this.showAd = true
          this.swiperList = res.data;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.popup {
  // width: 600px;
  // height: 450px;

  // .popupBox {
  //   width: 80%;
  //   margin: 0 0 0 264px;
    .DialogBox {
      // position: relative;
      // display: flex;
      align-items: center;
      // margin-top: 0 !important;

      .el-dialog__header {
        padding: 0;
      }
      .el-dialog__body {
        padding: 0;
      }
    }
    // .DialogBox .el-dialog {
    //   margin-top: 0vh !important;
    // }
    img {
      width: 100%;
      height: 400px;
    }
    [data-v-9ea40744] .el-carousel__arrow--right{
      left: 0;
    }
    .el-carousel__item.is-animating {
      transition: opacity 10s ease-in-out !important;
    }
    .el-carousel__indicators--outside{
      position: absolute;
      bottom: 10px;
    }
  // }

}
</style>
