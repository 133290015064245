<template>
  <div class="uploadWarks">
    <div class="upload" v-if="StudentAuth == 1">
      <Upload />
    </div>
    <div class="StudentAuth" v-else>
      <div v-if="userType == 'student'">
        <StudentAuth @studentAuth="getStudentAuth" />
      </div>
      <div v-else>
        <div class="studentAutMsg">
          <el-alert
            title="温馨提示：请先以个人入驻平台，再同步认证信息！同步认证信息后即可上传作品"
            :closable="false"
            type="warning"
            show-icon>
          </el-alert>
          <el-button style="background: #219560; margin-top: 30px; margin-left: 60px;" type="primary" @click="syncUserInfo">同步认证信息</el-button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Upload from "./upload.vue";
import StudentAuth from "./studentAuth.vue";
import { syncInfo } from "@/request/activity";

export default {
  components: { Upload, StudentAuth },
  props:{
    userType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      StudentAuth: 0,
    };
  },
  watch: {
    userType: { // 传入的地址id
      handler: function (v) {
        console.log('****userType******')
        console.log(v)
      }
    }
  },
  created() {
    console.log('*****upload*******')
    console.log(this.userType)
    this.StudentAuth = sessionStorage.getItem("StudentAuth")
    if (sessionStorage.getItem("activenum")) {
      this.StudentAuth = sessionStorage.getItem("activenum");
      sessionStorage.removeItem("activenum")
    }
    
  },
  methods: {
    getStudentAuth(i) {
      this.StudentAuth = i;
    },
    syncUserInfo() {
      const userId = sessionStorage.getItem("userId");
      if (!userId) {
        this.$message({
          message: "请先登录",
          type: "warning",
        });
      }
      syncInfo(userId).then((res) => {
        if (res.data.flag) {
          this.$message({
            message: "同步成功",
            type: "success",
          });
          this.StudentAuth = 1
        } else {
          if (res.msg == '未入驻') {
            this.$message({
              message: "您未入驻平台，请先点击右上角“我要入驻”，以个人入驻平台",
              type: "warning",
            });
          } else {
            this.$message({
              message: "同步失败，请联系客服",
              type: "warning",
            });
          }
        }
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.uploadWarks {
  width: 100%;
  .upload {
    margin: 0 auto;
  }

  .StudentAuth{

    .studentAutMsg {
      width: 60%;
      margin: 0 auto;
      padding-top: 25px;
      height: 200px;
    }
  }
}
</style>