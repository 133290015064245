<template>
  <div class="HomeView">
    <swiper/>
    <popupAd/>
    <div class="announcementBox">
      <div class="announcementLeft">
        <p>平台</p>
        <p>公告</p>
        <p @click="GoTrendsDetails">{{ TrendsNew.documentName }}</p>
      </div>
      <div class="announcementNew">
        最新
      </div>
    </div>
    <!--服务介绍-->
    <div class="serviceProcess">
      <div class="big_box">
        <div class="small_box">
          <div class="left_img">
            <img src="../assets/image/沟通-1.png" alt=""/>
          </div>
          <div class="right_content">
            <div class="content_sve">免费线上咨询</div>
            <div class="content_sve1">24小时线上免费咨询</div>
          </div>
        </div>
        <div class="small_box">
          <div class="left_img">
            <img src="../assets/image/1v1-1.png" alt=""/>
          </div>
          <div class="right_content">
            <div class="content_sve">1对1定制化设计</div>
            <div class="content_sve1">海量设计师定制化设计</div>
          </div>
        </div>
        <div class="small_box">
          <div class="left_img">
            <img src="../assets/image/油漆-1.png" alt=""/>
          </div>
          <div class="right_content">
            <div class="content_sve">售后维护养护</div>
            <div class="content_sve1">专业定期园林维护养护</div>
          </div>
        </div>
        <div class="small_box">
          <div class="left_img">
            <img src="../assets/image/在线预约-1.png" alt=""/>
          </div>
          <div class="right_content">
            <div class="content_sve">施工全流程监控</div>
            <div class="content_sve1">线上随时随地查看进度</div>
          </div>
        </div>
      </div>
    </div>
    <!--案例介绍-->
    <div class="case">
      <div class="case_title">
        <div class="case_title_content">
          <div class="text">
            <p class="big_con">优秀案例展示</p>
            <p class="big_con1">创立现代风景园林新境</p>
          </div>
        </div>
      </div>
      <div class="big_case_box">
        <div
            class="big_box"
            v-for="(item, index) in constructCaseList"
            :key="index"
            @click="GoCaseDetails(item)"
        >
          <div class="case_img">
            <img :src="item.coverImg" alt=""/>
          </div>
          <div class="case_introduce">
            <div class="case_parice">
              <p>{{ item.caseName }}</p>
              <span>{{ item.budget }}</span>
            </div>
            <span>{{
                getDardensStyle(item.gardensStyle) + "丨" + item.area + "㎡"
              }}</span>
          </div>
        </div>

      </div>
      <div class="more_case">
        <span @click="goCaseList">查看更多案例</span>
      </div>
    </div>
    <div class="case_nice">
      <div class="item">
        <div class="img img_f">
          <img src="../assets/image/sirendingzhi.png" alt=""/>
        </div>
        <div class="img_text">
          <p>私人庭院 定制化设计</p>
          <span>Customized design</span>
          <div class="yuyue">
            <p @click="goServers">立即预约</p>
          </div>
        </div>
        <div class="info" @click="goServers">
          <img src="../assets/image/sirendingzhi_01.png" alt=""/>
        </div>
      </div>
      <div class="item">
        <div class="img" @click="goServers">
          <img src="../assets/image/sirendingzhi_02.png" alt=""/>
        </div>
        <div class="info info_f" @click="goServers">
          <img src="../assets/image/sirendingzhi_03.png" alt=""/>
        </div>
      </div>
    </div>
    <!--服务流程-->
    <div class="serviceProcessImg">
      <div class="process_title">
        <p class="process_title_content">服务流程</p>
        <p class="process_title_smallcon">全流程服务</p>
      </div>
      <div class="process">
        <div class="prscess_1">
          <img src="../assets/image/流程预约.png" alt=""/>
        </div>
        <div class="process_dian">
          <img src="../assets/image/liucheng_dian.png" alt=""/>
        </div>
        <div class="prscess_img">
          <img src="../assets/image/测量设计.png" alt=""/>
        </div>
        <div class="process_dian">
          <img src="../assets/image/liucheng_dian.png" alt=""/>
        </div>
        <div class="prscess_img">
          <img src="../assets/image/方案修改.png" alt=""/>
        </div>
        <div class="process_dian">
          <img src="../assets/image/liucheng_dian.png" alt=""/>
        </div>
        <div class="prscess_img">
          <img src="../assets/image/签订合同.png" alt=""/>
        </div>
        <div class="process_dian">
          <img src="../assets/image/liucheng_dian.png" alt=""/>
        </div>
        <div class="prscess_img">
          <img src="../assets/image/施工装修.png" alt=""/>
        </div>
        <div class="process_dian">
          <img src="../assets/image/liucheng_dian.png" alt=""/>
        </div>
        <div class="prscess_img">
          <img src="../assets/image/验收竣工.png" alt=""/>
        </div>
        <div class="process_dian">
          <img src="../assets/image/liucheng_dian.png" alt=""/>
        </div>
        <div class="prscess_img">
          <img src="../assets/image/质保养护.png" alt=""/>
        </div>
      </div>
      <div class="process_text">
        <div class="process_txt">
          <p class="top">在线预约</p>
          <p class="bottom">线上提交联系方式</p>
        </div>
        <div class="process_txt">
          <p class="top">测量设计</p>
          <p class="bottom">线下上门实测实量</p>
        </div>
        <div class="process_txt">
          <p class="top">方案修改</p>
          <p class="bottom">与设计师确认设计方案</p>
        </div>
        <div class="process_txt">
          <p class="top">签订合同</p>
          <p class="bottom">与设计师定稿签订合同</p>
        </div>
        <div class="process_txt">
          <p class="top">装修施工</p>
          <p class="bottom">材料、施工队进场施工</p>
        </div>
        <div class="process_txt">
          <p class="top">竣工验收</p>
          <p class="bottom">设计施工完成验收项目</p>
        </div>
        <div class="process_txt">
          <p class="top">质保养护</p>
          <p class="bottom">后期园林养护维护</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {refreshToken} from "@/request/home/home";
import Swiper from "@/components/swiper/swiper";
import popupAd from "@/components/swiper/popupAd";
import scrollReveal from "scrollreveal";
import {getHomePageConstructCaseList} from "@/request/home/home";
import {getDicts} from "@/request/dict/data";
import {selectDictLabel, selectDictLabels, handleMoney} from "@/utils/weihua";
import {getIndustryTrendsList} from "@/request/IndustryTrends";

export default {
  name: "HomeView",
  components: { Swiper, popupAd },
  data() {
    return {
      constructCaseList: [],
      dardensStyleList: [],
      TrendsNew: {},
      scrollReveal: scrollReveal(),
    };
  },
  computed: {},
  created() {
    this.getHomeCase();
    this.PageApi()
    getDicts("msk_sys_gardens_style").then((res) => {
      this.dardensStyleList = res.data;
      this.dardensStyleList.unshift({
        dictLabel: "全部",
        dictValue: "",
      });
    });
  },
  mounted() {
    this.scrollReveal.reveal(".case", {
      // 动画的时长
      duration: 800,
      // 延迟时间
      delay: 0,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      origin: "bottom",
      // 回滚的时候是否再次触发动画
      reset: false,
      // 在移动端是否使用动画
      mobile: false,
      // 滚动的距离，单位可以用%，rem等
      distance: "200px",
      // 其他可用的动画效果
      opacity: 0.001,
      easing: "linear",
      scale: 0.9,
    });
    this.scrollReveal.reveal(".case_nice", {
      // 动画的时长
      duration: 800,
      // 延迟时间
      delay: 0,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      origin: "bottom",
      // 回滚的时候是否再次触发动画
      reset: false,
      // 在移动端是否使用动画
      mobile: false,
      // 滚动的距离，单位可以用%，rem等
      distance: "200px",
      // 其他可用的动画效果
      opacity: 0.001,
      easing: "linear",
      scale: 0.9,
    });
    this.scrollReveal.reveal(".serviceProcessImg", {
      // 动画的时长
      duration: 800,
      // 延迟时间
      delay: 0,
      // 动画开始的位置，'bottom', 'left', 'top', 'right'
      origin: "bottom",
      // 回滚的时候是否再次触发动画
      reset: false,
      // 在移动端是否使用动画
      mobile: false,
      // 滚动的距离，单位可以用%，rem等
      distance: "200px",
      // 其他可用的动画效果
      opacity: 0.001,
      easing: "linear",
      scale: 0.9,
    });
  },
  methods: {
    PageApi() {
      getIndustryTrendsList({
        pageNum: 1,
        pageSize: 8,
        dynamicsType: 5
      }).then((res) => {
        this.TrendsNew = res.rows[0];
      });
    },
    GoTrendsDetails() {
      this.$router.push({
        path: "/TrendsDetails",
        query: {
          id: this.TrendsNew.id,
          dynamicsType: this.TrendsNew.dynamicsType,
        },
      });
    },
    getHomeCase() {
      getHomePageConstructCaseList().then((res) => {
        const arr = res.rows.slice(0, 6);
        arr.forEach((item) => {
          item.budget = handleMoney(item.budget);
        });
        this.constructCaseList = arr;
      });
    },
    /**
     * 大数字转换，将大额数字转换为万、千万、亿等
     * @param value 数字值
     */

    getDardensStyle(value) {
      return selectDictLabel(this.dardensStyleList, value);
    },
    goCaseList() {
      this.$router.push({name: "CasePresentation"});
    },
    goServers() {
      this.$router.push({name: "FindService"});
    },
    GoCaseDetails(e) {
      this.$router.push({
        path: "/CasePresentationDetails",
        query: {
          id: e.id,
        },
      });
    },
    refreshTokenApi() {
      // refreshToken().then((res) => {
      // });
    },
  },
};
</script>
<style scoped>
::v-deep .el-carousel__arrow--right {
  left: 96vw !important;
}
</style>
<style lang="scss">
.HomeView {
  overflow: hidden;
  background-color: #fff;
  .announcementBox {
    width: 1320px;
    height: 55px;
    box-shadow: 2px 0px 15px 1px rgba(0, 0, 0, 0.1);
    margin: 25px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .announcementLeft {
      display: flex;
      //align-items: center;
      margin-left: 30px;

      p:nth-child(1) {
        font-size: 20px;
        font-family: Alimama ShuHeiTi;
        font-weight: bold;
        color: #4D4D4D;
      }

      p:nth-child(2) {
        font-size: 20px;
        font-family: Alimama ShuHeiTi;
        font-weight: bold;
        color: #188252;
      }

      p:nth-child(3) {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #4D4D4D;
        margin-left: 30px;
        margin-top: 4px;
        cursor: pointer;
      }

    }

    .announcementNew {
      width: 43px;

      height: 26px;
      background: #F66A1D;
      border-radius: 13px 13px 13px 0px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      text-align: center;
      margin-right: 25px;
      color: #FFFFFF;
      line-height: 26px;
    }
  }

  /**服务流程*/
  .serviceProcess {
    width: 1320px;
    margin: 40px auto;
    height: 109px;

    .big_box {
      display: flex;
      justify-content: space-between;

      .small_box {
        width: 306px;
        height: 109px;
        background-color: #fff;
        box-shadow: 2px 0px 15px 1px rgb(0 0 0 / 10%);
        //border: 1px solid #e6e6e6;
        border-radius: 2px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .left_img {
          cursor: pointer;
          margin-left: 60px;
        }

        .right_content {
          cursor: pointer;
          margin-left: 20px;
        }

        .content_sve {
          line-height: 30px;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #484d4a;
        }

        .content_sve1 {
          line-height: 30px;
          font-size: 13px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #a8a8a8;
        }
      }
    }
  }

  /**案例*/
  .case {
    width: 1320px;
    margin: 50px auto;

    .case_title_content {
      font-weight: 600;
      color: #3a3d44;
      height: 45px;
      line-height: 45px;
      position: relative;
      text-align: center;
      margin-bottom: 50px;

      .text {
        .big_con {
          font-size: 30px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #484d4a;
        }

        .big_con1 {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #7d807e;
        }
      }
    }

    .case_title_content:before {
      content: "";
      position: absolute;
      width: 143px;
      height: 1px;
      top: 100%;
      background-color: #188252;
      left: 28%;
    }

    .big_case_box {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 50px;

      .big_box {
        width: 418px;
        height: 328px;
        background: #ffffff;
        margin: 10px;
        border: 1px solid #e6e6e6;

        .case_img {
          height: 246px;
          margin-bottom: 20px;
          overflow: hidden;

          img {
            cursor: pointer;
            width: 100%;
            height: 100%;
            transition: all 0.6s;
          }
        }

        .case_img:hover img {
          transform: scale(1.3);
        }

        .case_introduce {
          display: flex;
          flex-direction: column;

          .case_parice {
            display: flex;
            justify-content: space-between;

            p {
              width: 70%;
              font-size: 18px;
              line-height: 30px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #323333;
              margin-left: 20px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            span {
              width: 28%;
              margin-right: 20px;
              text-align: right;
              display: inline-block;
              font-size: 18px;
              line-height: 30px;
              font-family: Source Han Sans CN;
              font-weight: 500;
              color: #f66a1d;
            }
          }

          span {
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #999999;
            margin-left: 20px;
            line-height: 20px;
          }
        }
      }

    }

    .more_case {
      width: 160px;
      height: 40px;
      background: #ffffff;
      border: 1px solid #e6e6e6;
      text-align: center;
      border-radius: 5%;
      margin: 20px auto 50px auto;
      cursor: pointer;

      span {
        font-size: 14px;
        line-height: 40px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #323333;
      }
    }

    .case_title_content:after {
      content: "";
      position: absolute;
      width: 143px;
      height: 1px;
      top: 100%;
      background-color: #188252;
      right: 28%;
    }
  }

  .case_nice {
    width: 1320px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .item {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;

      &:nth-of-type(2n) {
        flex-direction: row-reverse;
      }

      .img_f {
        margin-right: 20px;
      }

      .img {
        width: 480px;
        height: 340px;
        position: relative;
        overflow: hidden;

        img {
          cursor: pointer;
          width: 100%;
          height: 100%;
          transition: all 0.6s;
        }
      }

      .img_text {
        position: absolute;
        top: 10%;
        left: 2%;
      }

      .img_text {
        p {
          width: 152px;
          height: 69px;
          font-size: 30px;
          line-height: 30px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #fefefe;
        }

        span {
          display: inline-block;
          width: 127px;
          height: 9px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          text-transform: uppercase;
          color: #fefefe;
          line-height: 33px;
          opacity: 0.3;
          margin-bottom: 100px;
        }

        .yuyue {
          width: 100px;
          height: 36px;
          background: #f66a1d;
          border-radius: 18px;
          display: flex;
          justify-content: center;
          align-items: center;

          p {
            width: 75px;
            cursor: pointer;
            height: 34px;
            text-align: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #fefefe;
            line-height: 36px;
          }
        }
      }

      .img:hover img {
        transform: scale(1.3);
      }

      .info_f {
        margin-right: 20px;
      }

      .info {
        width: 830px;
        height: 340px;
        background-color: #000;
        overflow: hidden;

        img {
          cursor: pointer;
          width: 100%;
          height: 100%;
          transition: all 0.6s;
        }
      }

      .info:hover img {
        transform: scale(1.3);
      }
    }
  }

  //案例容器

  //流程
  .serviceProcessImg {
    display: flex;
    flex-direction: column;
    width: 1320px;
    margin: 50px auto;

    .process_title {
      text-align: center;

      .process_title_content {
        line-height: 30px;
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #484d4a;
      }

      .process_title_smallcon {
        line-height: 30px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #7d807e;
      }
    }

    .process {
      display: flex;
      justify-content: space-around;
      padding: 0 40px;

      .process_img {
        width: 200px;
        display: flex;
        align-self: center;
      }

      .process_dian {
        align-self: center;
      }
    }

    .process_text {
      display: flex;
      justify-content: space-around;
      text-align: center;

      .process_txt {
        width: 96px;

        .top {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #484d4a;
        }

        .bottom {
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #a5a8a7;
        }
      }
    }
  }
}

// .dian {
//   display: inline-block;
//   width: 5px;
//   height: 5px;
//   background: #ababab;
//   border-radius: 50%;
// }
</style>
