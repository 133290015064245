<template>
  <div class="findMaintenanceDetails">
    <div class="route">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="myrou" :to="{ path: '/HomeView' }"
          >首页</el-breadcrumb-item
        >
        <el-breadcrumb-item class="myrou" :to="{ path: '/findMaterials' }"
          >找材料</el-breadcrumb-item
        >
        <el-breadcrumb-item>材料详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="findMaterialsDetails">
      <div class="findMaterialsDetails_top">
        <div class="Details_img">
          <div class="LeftTopImg">
            <img :src="mainImgUrl" alt="" />
          </div>
          <ul class="Img_ul">
            <li
              v-for="(
                item, index
              ) in shopDetailList.baseShopGoodsCarouselImgList"
              :key="index"
              class="Img_li"
              @click="changeImg(item, index)"
            >
              <img
                :class="index === imgActiveIndex ? 'img_activeBorder' : ''"
                :src="item.carouselImg"
                style="width: 100%; height: 100%"
                alt=""
              />
            </li>
          </ul>
        </div>
        <div class="findMaterialsDetails_info">
          <div class="info_title">
            <p>{{ shopDetailList.goodsName }}</p>
          </div>
          <div class="info_servive">
            <p class="small_txt">
              价格<span class="fuhao"></span
              ><span class="parice">{{ skuPrice == 'null元' ? '面议' : skuPrice }}</span>
            </p>
            <p class="small_txt">
              服务<span class="service">{{
                getSetService(shopDetailList.setService)
              }}</span>
            </p>
            <p class="small_txt">
              物流<span class="logistics">{{
                getFreightSet(shopDetailList.freightSet)
              }}</span>
            </p>
            <p class="small_txt small_bom">
              商家<span class="logistics">{{
                shopDetailList.companyName
              }}</span>
            </p>
          </div>
          <div class="info_num">
            <p class="num_txt num_txt_top">
              商品规格<span
                :class="skuSpecsIndex == index ? 'txtActive' : 'num_box'"
                @click="skuSpecs(item, index)"
                v-for="(item, index) in shopDetailList.baseShopGoodsSkuList"
                :key="index"
              >
                <span class="num_number">{{ item.skuSpecs }}</span>
              </span>
            </p>
            <p class="num_txt num_txt_bom">
              数量<el-input-number
                class="buyNum"
                v-model="goodsNum"
                label="请输入数量"
              ></el-input-number
              ><span class="stock">库存{{ shopDetailList.goodsStock }}</span>
            </p>
          </div>
          <div class="info_btn">
            <div v-if="skuPrice == 'null元'" class="buy" @click="dialogTableVisible = true">
              <p>联系客服</p>
            </div>
            <div v-else class="buy" @click="consulting">
              <p>立即购买</p>
            </div>
            <!-- <div class="buyin">
              <p>加入购物车</p>
            </div> -->
          </div>
          <el-dialog
            title="联系客服"
            :show-close="false"
            width="400px"
            :visible.sync="dialogTableVisible"
          >
            <div class="inquiry">
              <div class="DialogTop">
                <div class="title">24小时客服电话</div>
                <div class="title1">029-85233571</div>
                <div class="img">
                  <img src="../../assets/image/baojia03.png" alt="" />
                </div>
                <div class="bom">扫一扫添加客服微信</div>
              </div>
              <p class="colseBox" @click="DialogClose">
                <i class="el-icon-close"></i>
              </p>
            </div>
          </el-dialog>
        </div>
      </div>
      <div class="findMaterialsDetails_bom">
        <div class="findMaterialsDetailsList">
          <div class="findMaterialsDetailsList_title">
            <p>其它商品推荐</p>
          </div>
          <div class="list">
            <div
              class="goodsList"
              v-for="(item, index) in otherProductsList"
              :key="index"
              @click="otherShopDetail(item.id)"
            >
              <div class="img">
                <img :src="item.coverImg" alt="" />
              </div>
              <div class="goodsInfo">
                <p class="goodsInfo_title">{{ item.skuPrice }}</p>
                <p class="goodsInfo_txt">{{ item.goodsName }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="goodsDetail">
          <div class="goodsDetail_title">
            <p>商品详情</p>
          </div>
          <div class="goods_top">
            <div class="goodsDetail_info">
              <div class="goodsDetail_name">
                <p class="goods_small">
                  商品名称<span>{{ shopDetailList.goodsName }}</span>
                </p>
              </div>
              <div class="goodsDetail_speciFications">
                <p class="goods_small goods_left">
                  商品规格<span>{{ skuSpecser }}</span>
                </p>
              </div>
            </div>
            <div class="bottom_bor"></div>
            <div class="goodsDetail_img" v-html="htmlDiv"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFindShopDetail, getFindShopGoods } from "@/request/findDesign";
import { getDicts } from "@/request/dict/data";
import { selectDictLabel, selectDictLabels, handleMoney } from "@/utils/weihua";
export default {
  name: "findMaintenanceDetails",
  components: {},
  data() {
    return {
      dialogTableVisible: false,
      inquiry: 0,
      shopDetailList: [],
      setServiceList: [],
      freightSetList: [],
      skuPrice: null,
      goodsNum: 1,
      skuSpecsIndex: 0,
      typeId: null,
      skuSpecser: null,
      mainImgUrl: null,
      htmlDiv: null,
      form: {
        pageNum: 1,
        pageSize: 5,
        typeId: null,
      },
      imgUrlList: [],
      imgActiveIndex: 0,
      goodsDetail: [
        {
          img: require("../../assets/image/goodsDetail_01.png"),
        },
        {
          img: require("../../assets/image/goodsDetail_02.png"),
        },
      ],
      detailList: [],
      otherProductsList: [],
    };
  },
  activated() {
    this.getShopDetail();
    this.getShopList();
    getDicts("msk_sys_shop_set_service").then((res) => {
      this.setServiceList = res.data;
    });
    getDicts("msk_sys_shop_freight_set").then((res) => {
      this.freightSetList = res.data;
    });
  },
  created() {},
  methods: {
    getShopDetail() {
      const id = this.$route.query.id;
      getFindShopDetail(id).then((res) => {
        this.shopDetailList = res.data;
        this.mainImgUrl = res.data.baseShopGoodsSkuList[0].skuImg;
        this.skuPrice = handleMoney(res.data.baseShopGoodsSkuList[0].skuPrice);
        this.skuSpecser = res.data.baseShopGoodsSkuList[0].skuSpecs;
        this.htmlDiv = res.data.goodsDetail.replace(
          /\<img/gi,
          '<img style="width:98%;height:auto;margin:10px 0 10px 10px" '
        );
        this.form.typeId = res.data.typeId;
      });
    },
    getShopList() {
      this.form.typeId = this.typeId;
      getFindShopGoods(this.form).then((res) => {
        const arr = res.rows;
        arr.forEach((item) => {
          item.skuPrice = handleMoney(item.skuPrice);
        });
        this.otherProductsList = arr;
      });
    },
    getSetService(value) {
      return selectDictLabel(this.setServiceList, value);
    },
    getFreightSet(value) {
      return selectDictLabel(this.freightSetList, value);
    },
    otherShopDetail(e) {
      const id = e;
      getFindShopDetail(id).then((res) => {
        // const arr = res.data;
        // arr.forEach((item) => {
        //   item.skuPrice = handleMoney(item.skuPrice);
        // });
        getDicts('msk_sys_shop_set_service').then(res2 => {
          res2.data.forEach(item => {
            if (res.data.setService == item.dictValue) {
              res.data.setService = item.dictLabel
            }
          })
        })
        getDicts('msk_sys_shop_freight_set').then(res2 => {
          res2.data.forEach(item => {
            if (res.data.freightSet == item.dictValue) {
              res.data.freightSet = item.dictLabel
            }
          })
        })
        this.shopDetailList = res.data;
        this.skuPrice = handleMoney(res.data.baseShopGoodsSkuList[0].skuPrice);
        this.mainImgUrl = res.data.baseShopGoodsSkuList[0].skuImg;
        this.form.typeId = res.data.typeId;
        this.skuSpecser = res.data.baseShopGoodsSkuList[0].skuSpecs;
        this.htmlDiv = res.data.goodsDetail.replace(
          /\<img/gi,
          '<img style="width:98%;height:auto;margin:10px 0 10px 10px" '
        );
      });
      document.documentElement.scrollTop = 0;
    },

    skuSpecs(e, i) {
      this.skuSpecsIndex = i;
      this.skuPrice = handleMoney(e.skuPrice);
      this.skuSpecser = e.skuSpecs;
      this.mainImgUrl = e.skuImg;
      // this.shopDetailList.baseShopGoodsSkuList.forEach((item) => {
      //   if (e.id == item.id) {
      //     this.skuPrice = handleMoney(item.skuPrice);
      //     this.skuSpecser = item.skuSpecs;
      //     this.mainImgUrl = e.skuImg;
      //   }
      // });
    },
    changeImg(item, index) {
      this.mainImgUrl = item.carouselImg;
      this.imgActiveIndex = index;
    },
    consulting() {
      const token = sessionStorage.getItem("token");
      const scope = this
      if (token) {
        // this.dialogTableVisible = true;
        const price = scope.skuPrice.substring(0, scope.skuPrice.length - 1)
        // TODO 跳转至支付页面
        let orderStr = []
        // console.log('-------------------')
        // console.log(this.shopDetailList)
        // console.log(this.skuSpecsIndex)
        orderStr.push({
          companyId: this.shopDetailList.companyId,
          companyName: this.shopDetailList.companyName,
          companyTotalPrice: this.shopDetailList.companyTotalPrice,
          detailList: [{
            setService: this.getSetService(this.shopDetailList.setService),
            companyId: this.shopDetailList.companyId,
            companyName: this.shopDetailList.companyName,
            freightSet: this.getFreightSet(this.shopDetailList.freightSet),
            goodsFreight: this.shopDetailList.goodsFreight,
            goodsId: this.shopDetailList.id,
            goodsName: this.shopDetailList.goodsName,
            goodsNum: this.goodsNum,
            skuId: this.shopDetailList.baseShopGoodsSkuList[this.skuSpecsIndex].id,
            skuImg: this.shopDetailList.baseShopGoodsSkuList[this.skuSpecsIndex].skuImg,
            skuPrice: this.shopDetailList.baseShopGoodsSkuList[this.skuSpecsIndex].skuPrice,
            skuSpecs: this.shopDetailList.baseShopGoodsSkuList[this.skuSpecsIndex].skuSpecs,
          }]
        })
        this.$router.push({
          name: "payMent", 
          query: {
            orderStr: orderStr
          }
        });
      } else {
        this.$message({
          message: "您还没有登录，请登录后查看",
          type: "success",
        });
        this.$router.push({ path: "/HomeView" });
        sessionStorage.setItem("loading", true);
        this.$router.go(0);
      }
    },
    DialogClose() {
      this.dialogTableVisible = false;
    },
  },
};
</script>
<style scoped>
::v-deep .el-breadcrumb__inner.is-link:hover {
  color: #219560 !important;
}
::v-deep .el-tabs__header {
  margin: 0;
}
::v-deep .el-tabs__item:hover {
  color: #219560;
  cursor: pointer;
}
::v-deep .el-tabs__item.is-active {
  font-size: 16px;
  color: #219560;
}

::v-deep .el-tabs__active-bar {
  background-color: #188252;
  height: 0;
  border-radius: 2px;
}

::v-deep.el-breadcrumb__inner:hover {
  color: #219560;
  cursor: pointer;
}
.myrou /deep/ .el-breadcrumb__inner {
  font-weight: 400;
}
</style>
<style lang="scss" >
.findMaintenanceDetails {
  width: 1320px;
  background-color: #FFFFFF;
  padding: 20px;
  margin: 0 auto;
  .route {
    width: 100%;
    height: 30px;
    padding-top: 20px;
  }
  .findMaterialsDetails {
    display: flex;
    flex-direction: column;
    .findMaterialsDetails_top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .Details_img {
        width: 30%;
        margin-right: 50px;
        display: flex;
        flex-direction: column;
        padding-bottom: 30px;
        .LeftTopImg {
          width: 400px;
          height: 400px;
          border-radius: 3px;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .Img_ul {
          display: flex;
          margin-top: 10px;
          margin-left: -30px;
          .Img_li {
            width: 80px;
            height: 80px;
            margin-left: 30px;
            background: #bdbdbd;
            border-radius: 3px;

            .img_activeBorder {
              border: 1px solid #0dcc73;
            }
          }
        }
      }
      .findMaterialsDetails_info {
        width: 65%;
        height: 500px;
        overflow: hidden;
        padding-top: 10px;
        .info_title {
          width: 100%;
          margin-left: 10px;
          border-bottom: 1px solid #ebebeb;
          margin-bottom: 20px;
          p {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #4a4d4b;
            margin: 0 0 20px 0;
          }
        }
        .info_servive {
          margin-left: 10px;
          margin-bottom: 20px;
          border-bottom: 1px solid #ebebeb;
          .small_txt {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #808080;
            line-height: 40px;
            .fuhao {
              margin-left: 50px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #f66a1d;
            }
            .parice {
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: 600;
              color: #f66a1d;
            }
            .service {
              margin-left: 50px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #4a4d4b;
            }
            .logistics {
              margin-left: 50px;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #4a4d4b;
            }
          }
          .small_bom {
            margin-bottom: 5px;
          }
        }
        .info_num {
          margin: 0 0 10px 10px;
          .num_txt_top {
            margin-bottom: 20px;
          }
          .num_txt {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            display: flex;
            flex-wrap: wrap;
            color: #808080;
            .num_box {
              padding: 0 10px;
              margin-left: 20px;
              display: inline-block;
              height: 34px;
              border: 1px solid #ebebeb;
              text-align: center;
              line-height: 34px;
              cursor: pointer;
              .num_number {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                line-height: 22px;
              }
            }
            .txtActive {
              padding: 0 10px;
              margin-left: 20px;
              display: inline-block;
              height: 34px;
              border: 1px solid #188252;
              text-align: center;
              line-height: 34px;
              cursor: pointer;
              .num_number {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #188252;
                line-height: 22px;
              }
            }
            .buyNum {
              margin-left: 48px;
            }
            .stock {
              margin-left: 20px;
            }
          }
          .num_txt_bom {
            margin-bottom: 67px;
          }
        }
        .info_btn {
          margin: 0 0 10px 10px;
          display: flex;
          .buy {
            width: 194px;
            height: 50px;
            background: #f66a1d;
            border: 1px solid #f66a1d;
            border-radius: 3px;
            p {
              cursor: pointer;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #fff;
              line-height: 50px;
              text-align: center;
            }
            // p:hover {
            //   background-color: #f66a1d;
            //   color: #fff;
            // }
          }
          .buyin {
            background: #188252;
            margin-left: 20px;
            width: 194px;
            height: 50px;
            border: 1px solid #188252;
            border-radius: 3px;
            p {
              cursor: pointer;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #fff;
              line-height: 50px;
              text-align: center;
            }
            // p:hover {
            //   background-color: #f66a1d;
            //   color: #fff;
            // }
          }
        }
      }
    }
    .inquiry {
      width: 347px;
      height: 401px;
      .DialogTop {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 30px;
        }
        .title1 {
          font-size: 30px;
          font-family: DIN;
          font-weight: 500;
          color: #f66a1d;
          line-height: 50px;
        }
        .img {
          width: 258px;
          height: 258px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .bom {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          line-height: 20px;
        }
      }
      .colseBox {
        cursor: pointer;
        position: absolute;
        top: 4px;
        right: -30px;
        border: 1px solid #bdbdbd;
        border-radius: 50%;
        i {
          font-size: 24px;
          color: #bdbdbd;
        }
      }
    }
    .findMaterialsDetails_bom {
      display: flex;
      .findMaterialsDetailsList {
        width: 20%;
        display: flex;
        flex-direction: column;
        .findMaterialsDetailsList_title {
          height: 50px;
          background: #f7f7f7;
          border: 1px solid #ebe9e8;
          p {
            margin-left: 20px;
            line-height: 50px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
          }
        }
        .list {
          padding: 20px;
          border: 1px solid #ebebeb;
          border-top: none;
          .goodsList {
            width: 100%;
            height: 268px;
            margin-bottom: 30px;
            border: 1px solid #ebebeb;
            .img {
              cursor: pointer;
              width: 100%;
              height: 180px;
              margin-bottom: 10px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .goodsInfo {
              margin-left: 10px;
              .goodsInfo_title {
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #f6501d;
                margin-bottom: 10px;
              }
              .goodsInfo_txt {
                margin-bottom: 20px;
                overflow: hidden;
                display: -webkit-box; /*弹性伸缩盒子*/
                -webkit-box-orient: vertical; /*子元素垂直排列*/
                -webkit-line-clamp: 2; /*可以显示的行数，超出部分用...表示*/
                text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
              }
            }
          }
        }
      }
      .goodsDetail {
        margin-left: 2%;
        width: 78%;
        border: 1px solid #ebebeb;
        .goodsDetail_title {
          height: 50px;
          background: #f7f7f7;
          border-bottom: 1px solid #ebe9e8;
          p {
            margin-left: 20px;
            line-height: 50px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
          }
        }
        .goods_top {
          display: flex;
          flex-direction: column;
          padding: 30px;
          .goodsDetail_info {
            margin-bottom: 20px;

            display: flex;
            .goodsDetail_name {
              .goods_small {
                font-size: 14px;
                font-weight: 400;
                color: #999999;
                display: flex;
                align-items: center;
                span {
                  display: inline-block;
                  /* 文字过多换行显示，现在强行让文字在一行显示 */
                  white-space: nowrap;
                  /* 然后然溢出的部分文字隐藏 */
                  overflow: hidden;
                  /* 文字溢出显示用省略号。  */
                  text-overflow: ellipsis;
                  width: 200px;
                  margin-left: 20px;
                  font-size: 14px;
                  font-weight: 400;
                  color: #333;
                }
              }
            }
            .goodsDetail_speciFications {
              margin-left: 90px;
              .goods_small {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
                span {
                  display: inline-block;
                  margin-left: 20px;
                  font-size: 14px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #333;
                }
              }
            }
          }
          .goodsDetail_infoText {
            display: flex;
            margin-bottom: 20px;
            p:nth-child(1) {
              width: 92px;
              font-size: 14px;
              line-height: 2;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #999999;
            }
            p:nth-child(2) {
              margin-left: 20px;
              font-size: 14px;
              line-height: 2;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333;
            }
          }
          .bottom_bor {
            width: 100%;
            height: 2px;
            border-bottom: 1px solid #ebebeb;
            margin-bottom: 50px;
          }
          .goodsDetail_img {
            width: 100%;
            margin: 0 auto;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
</style>