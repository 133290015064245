<template>
  <div class="personalData">
    <div class="title">个人资料</div>
    <div class="form">
      <el-form ref="form" :model="form" :rules="rules" label-width="100">
        <el-form-item
          label=""
          prop="certImg"
          style="width: 500px; display: flex; margin-left: 80px"
        >
          <div class="imgBox">
            <div class="img">
              <img v-if="form.personalImg" :src="form.personalImg" />
              <img v-else src="../../assets/image/tx.png" />
            </div>

            <el-upload
              class="avatar-uploader"
              action=""
              accept=".jpg,.JPG,.jpeg,.JPEG,.PNG,.png"
              :http-request="UploadImageOne"
              :before-upload="beforeUploadOne"
              :show-file-list="false"
            >
              <el-button style="margin-left: 30px" size="small" type="primary"
                >点击上传</el-button
              >
            </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="用户名称" prop="visitorName">
          <el-input v-model="form.visitorName" style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="性别" style="margin-left: 10px">
          <el-radio-group v-model="form.visitorSex" style="margin-left: 30px">
            <el-radio label="0">男</el-radio>
            <el-radio label="1">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="生日" style="margin-left: 10px">
          <el-date-picker
            style="margin-left: 30px"
            v-model="form.visitorBirthday"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择出生日期"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          label="所在城市"
          prop="detailArea"
          style="margin-left: 10px"
        >
          <el-cascader
            style="width: 300px"
            placeholder="请选择省市区"
            v-model="selectedOptions"
            :options="options"
            clearable
            filterable
            @change="handleChange"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" style="width: 500px; margin-left: 10px">
          <el-input
            maxlength="120"
            type="textarea"
            v-model="form.detailAddress"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            style="background: #219560; margin-left: 160px"
            type="primary"
            @click="submitForm('ruleForm')"
            >保 存</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  provinceAndCityData,
  regionData,
  provinceAndCityDataPlus,
  regionDataPlus,
  CodeToText,
  TextToCode,
} from "element-china-area-data";
import { getOssToken, getInfo } from "@/request/login";
import OSS from "ali-oss";
import { getUserData, subUserData } from "@/request/mine";
export default {
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() >= Date.now() - 24 * 3600 * 1000;
        },
      },
      form: {
        visitorName: null,
        visitorPhone: null,
        visitorSex: null,
        detailArea: null,
        detailAddress: null,
        personalImg: null,
        visitorBirthday:null,
      },
      userId: null,
      selectedOptions: [],
      options: regionData,
      rules: {
        visitorName: [
          {
            min: 2,
            max: 30,
            required: true,
            message: "用户名不能为空,且必须在2-30位",
            trigger: "blur",
          },
        ],
      },
      OssForm: {},
    };
  },
  created() {
    getOssToken().then((res) => {
      if (res.code === 0) {
        this.OssForm = res.data;
      }
    });
    this.OssForm = JSON.parse(sessionStorage.getItem("aliOss"));
    this.userId = sessionStorage.getItem("userId");
    this.getUseridData(this.userId);
  },
  methods: {
    getUseridData(userId) {
      getUserData(userId).then((res) => {
        this.form = res.data;
        if (this.form.visitorSex != null) {
          this.form.visitorSex = res.data.visitorSex.toString();
        }
        this.setSelectedOptions();
      });
    },
    UploadImageOne(options) {
      try {
        let nowDate = new Date();
        let dateTime = {
          year: nowDate.getFullYear(),
          month: nowDate.getMonth() + 1,
          date: nowDate.getDate(),
        };
        let that = this;
        let client = new OSS({
          region: "oss-cn-beijing",
          accessKeyId: that.OssForm.accessKeyId,
          accessKeySecret: that.OssForm.accessKeySecret,
          stsToken: that.OssForm.token,
          bucket: "fengjingyuanlin",
          timeout: 600000,
          secure: true,
        });
        let file = options.file; // 拿到 file
        let extendName = file.name.substring(file.name.lastIndexOf(".") + 1);
        let fileName = file.name.substr(0, file.name.lastIndexOf("."));
        let date = new Date().getTime();
        let fileNames = `${date}_${file.size}_${Math.round(
          Math.random() * 10000
        )}`;
        let folderName =
          "image/" +
          dateTime.year +
          "-" +
          dateTime.month +
          "-" +
          dateTime.date +
          "/" +
          fileNames +
          "." +
          extendName;

        client.put(folderName, file).then((res) => {
          if (res.res.statusCode === 200) {
            that.form.personalImg = res.url;
          } else {
            options.onError("上传失败");
          }
        });
      } catch (e) {
        options.onError("上传失败");
      }
    },
    //省市区选择后，form表单数据保存
    handleChange(value) {
      var loc = "";
      var code = "";
      loc +=
        CodeToText[this.selectedOptions[0]] +
        "-" +
        CodeToText[this.selectedOptions[1]] +
        "-" +
        CodeToText[this.selectedOptions[2]];
      this.form.detailArea = loc;
      this.form.deptAreaCode = code;
    },
    /** 地区回填 */
    setSelectedOptions() {
      if (this.form.detailArea) {
        //重置this.selectedOptions
        this.selectedOptions = [];
        let address = this.form.detailArea.split("-");
        // 省份
        this.selectedOptions.push(TextToCode[address[0]].code);
        // 城市
        let cityCode = TextToCode[address[0]][address[1]].code;
        this.selectedOptions.push(cityCode);
        // 地区
        let areaCode = TextToCode[address[0]][address[1]][address[2]].code;
        this.selectedOptions.push(areaCode);
      }
    },
    goHome() {
      this.$router.push({ path: "/HomeView" });
    },
    GoIsShow() {
      this.$refs.Navigation;
    },
    // 上传文件之前对文件大小和类型做限制
    beforeUploadOne(file) {
      //图片类型
      let types = ["image/jpeg", "image/jpg", "image/png"];
      const isImage = types.includes(file.type);
      //图片大小
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isImage) {
        this.$message({
          message: "上传图片只能是 JPG、JPEG、PNG 格式!",
          type: "warning",
        });
        return false;
      }
      if (!isLt2M) {
        this.$message({
          message: "上传图片大小不能超过 2M!",
          type: "warning",
        });
        return false;
      }
      return true;
    },
    // beforeUploadOne(file) {
    //   //图片大小
    //   const isLt2M = file.size / 1024 / 1024 < 2;
    //   if (!isLt2M) {
    //     this.$message({
    //       message: "上传图片的大小不能超过 2M!",
    //       type: "warning",
    //     });
    //     return false;
    //   }
    //   return isLt2M;
    // },
    getInfo() {
      const token = sessionStorage.getItem("token");
      if (token) {
        getInfo().then((res) => {
          if (res && res.user) {
            sessionStorage.setItem("userId", res.user.userId);
            sessionStorage.setItem("phone", res.user.phonenumber);
            sessionStorage.setItem("nickName", res.user.nickName);
            this.nickName = res.user.nickName;
            this.phone = res.user.phonenumber;
          }
        });
      }
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          subUserData(this.form).then((res) => {
            if (res.code == 200) {
              this.$message({
                message: "保存成功",
                type: "success",
              });
              this.getInfo();
              this.getUseridData(this.userId);
              this.$router.go(0);
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__error {
  margin-left: 80px;
}
.personalData {
  width: 100%;
  padding: 30px 0px;
  .title {
    padding: 0 0 20px 30px;
    margin-bottom: 20px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    border-bottom: 1px solid #ebebeb;
  }
  .form {
    padding: 0 0 0 30px;
    .imgBox {
      display: flex;
      align-items: center;

      .img {
        width: 100px;
        height: 100px;
        border: 1px solid #ccc;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>