<template>
  <div class="findMaterials">
    <div class="navBar">
      <Breadcrumb />
    </div>
    <div class="materials_box">
      <div class="classificationFilter">
        <span class="serverTit" style="border-right: 0; border-bottom: 0" >商品分类</span>
        <ul class="serversType" style="border-bottom: 0">
          <li :class="IndexActiveOne === item.id ? 'txtActive' : 'txt'"
              @click="GoServersTypeActive(item.id)"
              v-for="(item, index) in shopGoodsType" :key="index" >
            {{ item.typeName }}
          </li>
        </ul>
      </div>

      <div class="classificationFilter" v-if="filterlist.length != 0" >
        <span class="serverTit" style="border-right: 0">二级分类</span>
        <ul class="serversType">
          <li :class="IndexActiveTwo === item.id ? 'txtActive' : 'txt'"
              @click="GoTutorialLevActive(item.id)" v-for="item in filterlist" :key="item.id" >
            {{ item.typeName }}
          </li>
        </ul>
      </div>

      <div class="classificationFilter classificationFilter_bom" v-if="filterlist2.length != 0" >
        <span class="serverTit" style="border-right: 0">三级分类</span>
        <ul class="serversType">
          <li :class="IndexActiveThree === item.id ? 'txtActive' : 'txt'"
              @click="getThirdType(item.id)" v-for="item in filterlist2" :key="item.id" >
            {{ item.typeName }}
          </li>
        </ul>
      </div>

      <!-- <div class="sort">
        <ul class="serversType">
          <li
            :class="sortIndex === index ? 'txtActive' : 'txt'"
            @click="sort(item.id)"
            v-for="(item, index) in sortList"
            :key="index"
          >
            {{ item.con }}
          </li> -->
      <!-- <div class="on">
            <a
              href="javascript:void(0)"
              data-category="price"
              data-orderby="asc"
              class="js_category"
              >价格
              <i class="angle_top"></i>
              <i class="angle_bottom"></i>
            </a>
          </div> -->
      <!-- </ul>
      </div> -->
      <div class="materials_list" v-if="materialsList.length != 0">
        <div class="materAlsBox">
          <div
            class="materialsBox"
            v-for="(item, index) in materialsList"
            :key="index"
          >
            <div class="materialsImg" @click="findMaterialsDetails(item.id)">
              <img :src="item.coverImg" alt="" />
            </div>

            <p class="materials_parice">{{ item.skuPrice == 'null元' ? '价格面议' : item.skuPrice}}</p>
            <span class="materials_jieshao">{{ item.goodsName }}</span>
          </div>
        </div>
      </div>
      <div class="emptyList" v-else>
        <p>暂无材料数据</p>
      </div>
    </div>
    <Pagination
      v-show="Total > 0"
      :total="Total"
      style="text-align: center; margin: 30px auto"
      :page.sync="form.pageNum"
      :limit.sync="form.pageSize"
      @pagination="PageApi"
    />
  </div>
</template>

<script>
import Breadcrumb from "@/components//Breadcrumb/Breadcrumb.vue";
import Pagination from "@/components/Pagination/Pagination";
import { handleMoney } from "@/utils/weihua";
import { getFindShopGoods, getFindShopGoodsType } from "@/request/findDesign";
import { getSeatch } from "@/request/home/home";
export default {
  name: "findMaterials",
  components: { Breadcrumb, Pagination },
  data() {
    return {
      form: {
        pageNum: 1,
        pageSize: 999,
        typeId: null,
        type: null,
        searchInfo: null,
        secondTypeId: null,
        thirdTypeId: null,
        parentTypeId: null,
        pcSearch: 1,
      },
      sortIndex: 1,
      sortList: [
        {
          id: 1,
          con: "综合排序",
        },
        {
          id: 2,
          con: "最新",
        },
      ],
      Total: 0,
      refreshLock: false,
      getDataLock: false,
      IndexActiveOne: "",
      filterlist: [],
      filterlist2: [],
      IndexActiveTwo: 0,
      IndexActiveThree: 0,
      materialsList: [],
      shopGoodsType: [],
    };
  },
  watch: {
    IndexActiveOne: {
      handler: function (v) {
        console.log('--IndexActiveOne--' + v)
        if (!this.refreshLock) {
          this.form.parentTypeId = v;
          this.form.typeId = v;
          if (v != "") {
            getFindShopGoodsType({ parentTypeId: v }).then(
              (res) => {
                this.filterlist = res.rows;
              }
            );
          } else {
            this.filterlist = [];
          }
          this.filterlist2 = []
          if (!this.getDataLock) {
            this.refreshData()
            this.refreshLock = true
            this.IndexActiveTwo = ''
            this.IndexActiveThree = ''
            this.refreshLock = false
          }
        }
      }
    },
    IndexActiveTwo: {
      handler: function (v) {
        console.log('--IndexActiveTwo--' + v)
        if (!this.refreshLock) {
          this.form.secondTypeId = v;
          if (v != "") {
            getFindShopGoodsType({ parentTypeId: v }).then(
              (res) => {
                this.filterlist2 = res.rows;
              }
            );
          }
          if (!this.getDataLock) {
            this.refreshData()
            this.form.secondTypeId = null
            this.refreshLock = true
            this.IndexActiveThree = ''
            this.refreshLock = false
          }
        }
      }
    },
    IndexActiveThree: {
      handler: function (v) {
        console.log('--IndexActiveThree--' + v)
        if (!this.refreshLock) {
          this.form.thirdTypeId = v;
          if (!this.getDataLock) {
            this.refreshData()
            this.form.thirdTypeId = null
          }
        }
      }
    },
    getDataLock: {
      handler: function (v) {
        if (!v) {
          // 解开加载数据锁时再更新数据
          this.refreshData()
        }
      }
    },
  },
  activated() {
    
  },
  created() {
    const scope = this
    getFindShopGoodsType(this.form).then((res) => {
      scope.shopGoodsType = res.rows;
      scope.shopGoodsType.unshift({
        id: "",
        typeName: "全部",
      });


      scope.form.personIdentity = this.$route.query.id;
      scope.getDataLock = true
      console.log('--activated--')
      console.log(this.$route.query)

      if (this.$route.query && this.$route.query.typeone) {
        const tmp = this.$route.query
        scope.IndexActiveOne = tmp.typeone
        if (tmp.typetwo) {
          setTimeout(() => {
            scope.IndexActiveTwo = tmp.typetwo
          }, 300);
          if (tmp.typethree) {
            setTimeout(() => {
              scope.IndexActiveThree = tmp.typethree
            }, 600);
          }
        }
      }
      scope.getDataLock = false

      // TODO 待优化数据加载
      // if (this.$route.query && this.$route.query.typeone) {
      //   const tmp = this.$route.query
      //   scope.IndexActiveOne = tmp.typeone
      //   setTimeout(() => {
      //     if (tmp.typetwo) {
      //       scope.IndexActiveTwo = tmp.typetwo
      //     } else {
      //       scope.getDataLock = false
      //     }
      //   }, 300);
      //   setTimeout(() => {
      //     if (tmp.typethree) {
      //       scope.IndexActiveThree = tmp.typethree
      //     }
      //     scope.getDataLock = false
      //   }, 400);
      // } else {
      //   scope.getDataLock = false
      // }

      
      // this.refreshData()
    });
  },
  methods: {
    PageApi() {
      getFindShopGoods(this.form).then((res) => {
        const arr = res.rows;
        arr.forEach((item) => {
          item.skuPrice = handleMoney(item.skuPrice);
        });
        this.materialsList = arr;
        this.Total = res.total;
      });
    },
    getSeatchList(data) {
      getSeatch(data).then((res) => {
        if (res.code == 200) {
          this.materialsList = res.rows;
          this.Total = res.total;
        }
      });
    },
    sort(e) {
      this.sortIndex = e;
      this.form.pcSearch = this.sortIndex;
      this.refreshData()
    },
    refreshData(){
      if (this.$route.query.type) {
        this.form.type = this.$route.query.type;
        this.form.searchInfo = this.$route.query.searchInfo;
        this.getSeatchList(this.form);
      } else {
        this.PageApi();
      }
    },
    GoServersTypeActive(e) {
      this.IndexActiveOne = e;  // 通过监听更新商品展示
    },
    GoTutorialLevActive(e) {
      this.IndexActiveTwo = e;  // 通过监听更新商品展示
    },
    getThirdType(e) {
      this.IndexActiveThree = e;  // 通过监听更新商品展示
    },
    findMaterialsDetails(e) {
      this.$router.push({ name: "findMaterialsDetails", query: { id: e } });
    },
  },
};
</script>
<style scoped>
::v-deep .el-breadcrumb__inner.is-link:hover {
  color: #219560 !important;
}
::v-deep .el-tabs__header {
  margin: 0;
}
::v-deep .el-tabs__item:hover {
  color: #219560;
  cursor: pointer;
}
::v-deep .el-tabs__item.is-active {
  font-size: 16px;
  color: #219560;
}

::v-deep .el-tabs__active-bar {
  background-color: #188252;
  height: 0;
  border-radius: 2px;
}

::v-deep.el-breadcrumb__inner:hover {
  color: #219560;
  cursor: pointer;
}
</style>
<style scoped lang="scss">
::v-deep .btn-prev {
  padding: 0 5px !important;
}
::v-deep .btn-next {
  padding: 0 5px !important;
}
.findMaterials {
  width: 1320px;
  margin: 0 auto;
  .navBar {
    padding: 20px 0 20px 0;
    width: 100%;
  }
  .materials_box {
    display: flex;
    flex-direction: column;
    .classificationFilter {
      width: 100%;
      display: flex;

      .serverTit {
        display: inline-block;
        width: 92px;
        min-height: 44px;
        background: #f7f7f7;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #4a4d4b;
        padding-top: 20px;
        text-align: center;
      }

      .serversType {
        width: 100%;
        min-height: 44px;
        background: #ffffff;
        padding-left: 20px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .txt {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          cursor: pointer;
          padding: 20px;
          color: #636665;
        }

        .txtActive {
          background: #188252;
          font-size: 13px;
          font-family: Source Han Sans CN;
          font-weight: 300;
          cursor: pointer;
          color: #ffffff;
          padding: 5px 20px;

          border-radius: 20px;
        }

        .txt:hover {
          color: #188252;
        }
      }
    }
    .classificationFilter_bom {
      margin-bottom: 20px;
    }
    .sort {
      width: 100%;
      height: 36px;
      background: #f5f5f5;
      border: 1px solid #ebebeb;
      .serversType {
        width: 50%;
        display: flex;
        padding: 0 20px;
        li {
          cursor: pointer;
          margin-right: 30px;
        }

        .txt {
          line-height: 36px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #808080;
          cursor: pointer;
        }

        .txtActive {
          line-height: 36px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #188252;
          cursor: pointer;
        }

        .txt:hover {
          color: #188252;
        }
        .on {
          width: 50px;
          height: 36px;
          display: table-cell;
          position: relative;
        }

        .on a {
          display: block;
          overflow: hidden;
          width: 100%;
          height: 36px;
          line-height: 36px;
          text-align: center;
          color: #5e5e5e;
        }

        .angle_top {
          content: "";
          width: 0;
          height: 0;
          display: block;
          border-style: solid;
          border-color: transparent transparent #5e5e5e;
          position: absolute;
          transform: rotate(180deg);
          bottom: 10px;
          left: 42px;
        }

        .angle_bottom {
          content: "";
          width: 0;
          height: 0;
          display: block;
          border-style: solid;
          border-color: transparent transparent #5e5e5e;
          position: absolute;
          top: 10px;
          left: 42px;
        }
      }
    }
    .emptyList {
      width: 100%;
      height: 300px;
      display: flex;
      align-items: center;
      text-align: center;
      p {
        width: 100%;
        color: #bdbdbd;
        font-size: 24px;
        line-height: 300px;
        text-align: center;
      }
    }
    .materials_list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding-top: 20px;

      .materAlsBox {
        display: flex;
        flex-wrap: wrap;
        margin-left: -17px;
      }

      .materialsBox {
        width: 224px;
        display: flex;
        height: 289px;
        background: #ffffff;
        flex-direction: column;
        margin-left: 18px;
        padding: 10px;
        margin-bottom: 20px;
        .materialsImg {
          width: 100%;
          height: 200px;
          padding-top: 10px;
          margin-bottom: 10px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .materials_parice {
          height: 30px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #f6501d;
          line-height: 20px;
          cursor: pointer;
        }
        .materials_jieshao {
          cursor: pointer;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          width: 100%;
          display: -webkit-box; /*弹性伸缩盒子*/
          overflow: hidden;
          -webkit-box-orient: vertical; /*子元素垂直排列*/
          -webkit-line-clamp: 2; /*可以显示的行数，超出部分用...表示*/
          text-overflow: ellipsis; /*（多行文本的情况下，用省略号“…”隐藏溢出范围的文本)*/
          color: #333333;
        }
      }
    }
  }
}
</style>
